import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraphGroup(id, accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;
    const url = `https://graph.microsoft.com/v1.0/me`;
    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraphGroupMember(graphid, graphAccessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${graphAccessToken}`;
    const url = `https://graph.microsoft.com/v1.0/users/${graphid}/memberOf/microsoft.graph.group?$count=true&$orderby=displayName&$filter=displayName in
     ('saudi_tov_admins')`
    //  &$select=displayName,id
    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");
    const options = {
        method: "GET",
        headers: headers
    }
    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callSearchMSGraphGroup(search, graphAccessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${graphAccessToken}`;
    const url = `https://graph.microsoft.com/beta/groups/${process.env.REACT_APP_GROUPID}/members/microsoft.graph.user?$count=true&$orderby=displayName&$search="displayName:${search}"&$select=displayName,id,givenName,surname,mail,mobilePhone,onPremisesSamAccountName`
    //  https://graph.microsoft.com/v1.0/groups/{id}/members/microsoft.graph.user?$count=true&$orderby=displayName&$search="displayName:Pr"&$select=displayName,id
    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");
    const options = {
        method: "GET",
        headers: headers
    }
    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callSecondaryOwners(graphAccessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${graphAccessToken}`;
    headers.append("Authorization", bearer);
    headers.append("ConsistencyLevel", "eventual");
    const options = {
        method: "GET",
        headers: headers
    }
    const group1 = `https://graph.microsoft.com/beta/groups/d09be084-7d85-4c3f-800a-a1b8afa34dfb/members/microsoft.graph.user?$count=true&$select=givenName,onPremisesSamAccountName,displayName,surname,mail,id`;
    const group1Members = await fetch(group1, options).then(response => response.json())
        .catch(error => console.log(error));
    const group2 = `https://graph.microsoft.com/beta/groups/a8be05ef-1410-438d-85e5-039ce274ed21/members/microsoft.graph.user?$count=true&$select=givenName,onPremisesSamAccountName,displayName,surname,mail,id`;
    const group2Members = await fetch(group2, options).then(response => response.json())
        .catch(error => console.log(error));
    const group3 = `https://graph.microsoft.com/beta/groups/29010779-102a-4cac-9769-c48021fe2930/members/microsoft.graph.user?$count=true&$select=givenName,onPremisesSamAccountName,displayName,surname,mail,id`;
    const group3Members = await fetch(group3, options).then(response => response.json())
        .catch(error => console.log(error));
    let data = Promise.all([group1Members, group2Members, group3Members]).then(result =>
        [...result[0].value, ...result[1].value, ...result[2].value],
    ).finally(() => console.log('done'));
    return data
}
